.login-container {
  position: relative;
}

.front-image {
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  background-image: url("../assets/images/coat_of_arms.png");
}

.shadow-image {
  background-repeat: no-repeat;
  z-index: -1;
  position: absolute;
  top: 0px;
  right: 0px;
  width: 200px !important;
  height: 200px !important;
}

.form {
  text-align: center;
  position: absolute;
  width: 90%;
}

.logo {
  margin-bottom: 16px;
  width: 200px;
  height: 180px;
}

.title {
  font-weight: 700;
  font-size: 26px !important;
  color: #2b2f38;
}

.subtitle {
  font-weight: 400;
  font-size: 16px;
  color: #667085;
}

.error-text {
  color: red;
  text-align: start;
  font-size: 14px;
}

.checkbox-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  align-items: center;
}

.checkbox-label {
  font-size: 14px;
  font-weight: 500;
  color: #48505e;
}

.forgot-password {
  text-decoration: none;
  color: #1366d9;
  font-size: 14px;
  font-weight: 500;
}

.login-footer {
  position: absolute;
  bottom: 5px;
  text-align: center;
}

.login-footer-link {
  color: #4191ff;
  cursor: pointer;
}
@media (min-width: 832px) and (max-width: 1440px) {
  .form {
    width: 85%;
  }

  .front-image {
    background-image: url("../assets/images/frontImage.svg");
  }

  .shadow-image {
    width: 150px !important;
    height: 150px !important;
    position: absolute;
    top: 0px;
    right: 0;
  }
  .logo {
    margin-bottom: 3px;
    width: 80px;
    height: 80px;
  }

  .title {
    font-weight: 700 !important;
    font-size: 24px !important;
    color: #2b2f38;
  }
}

@media (min-width: 1200px) {
  .form {
    width: 70%;
  }

  .front-image {
    background-image: url("../assets/images/frontImage.svg");
  }

  .shadow-image {
    width: 150px;
    height: 150px;
    position: absolute;
    top: 0px;
    right: 0;
    background-size: cover;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .form {
    width: 75%;
  }

  .front-image {
    background-image: url("../assets/images/frontImage.svg");
  }

  .shadow-image {
    width: 80px !important;
    height: 80px !important;
    position: absolute;
    top: 0px;
    right: 0px;
    background-size: cover;
  }
  .logo {
    margin-bottom: 6px;
    width: 60px;
    height: 60p;
  }

  .title {
    font-weight: 700 !important;
    font-size: 20px !important;
    color: #2b2f38;
  }

  .subtitle {
    font-weight: 400;
    font-size: 10px !important;
    color: #667085;
  }

  .error-text {
    color: red;
    text-align: start;
    font-size: 8px;
  }

  .checkbox-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
  }

  .checkbox-label {
    font-size: 10px;
    font-weight: 500;
    color: #48505e;
  }

  .forgot-password {
    text-decoration: none;
    color: #1366d9;
    font-size: 10px;
    font-weight: 500;
  }

  .login-footer {
    position: absolute;
    bottom: 5px;
    text-align: center;
    font-size: 10px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .form {
    width: 80%;
  }

  .front-image {
    background-image: url("../assets/images/frontImage.svg");
    background-size: cover;
    background-repeat: no-repeat !important;
    height: 100vh;
    object-fit: cover !important;
  }

  .shadow-image {
    width: 90px !important;
    height: 90px !important;
    position: absolute;
    top: 0px;
    right: 0px;
    background-size: cover !important;
  }
  .logo {
    margin-bottom: 6px;
    width: 60px;
    height: 60p;
  }

  .title {
    font-weight: 700 !important;
    font-size: 20px !important;
    color: #2b2f38;
  }

  .subtitle {
    font-weight: 400;
    font-size: 10px !important;
    color: #667085;
  }

  .error-text {
    color: red;
    text-align: start;
    font-size: 8px;
  }

  .checkbox-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
  }

  .checkbox-label {
    font-size: 10px;
    font-weight: 500;
    color: #48505e;
  }

  .forgot-password {
    text-decoration: none;
    color: #1366d9;
    font-size: 10px;
    font-weight: 500;
  }

  .login-footer {
    position: absolute;
    bottom: 5px;
    text-align: center;
    font-size: 10px;
  }
}

@media (max-width: 767px) {
  .form {
    width: 90%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  /* .front-image {
    background-image: url('../assets/images/frontImage.svg');
  } */

  .shadow-image {
    width: 70px !important;
    height: 70px !important;
    position: absolute;
    top: 0px;
    right: 0px;
    background-size: cover;
  }
  .logo {
    margin-bottom: 6px;
    width: 60px;
    height: 60p;
  }

  .title {
    font-weight: 700 !important;
    font-size: 20px !important;
    color: #2b2f38;
  }

  .subtitle {
    font-weight: 400;
    font-size: 10px !important;
    color: #667085;
  }

  .error-text {
    color: red;
    text-align: start;
    font-size: 8px;
  }

  .checkbox-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
  }

  .checkbox-label {
    font-size: 10px;
    font-weight: 500;
    color: #48505e;
  }

  .forgot-password {
    text-decoration: none;
    color: #1366d9;
    font-size: 10px;
    font-weight: 500;
  }

  .login-footer {
    position: absolute;
    bottom: 5px;
    text-align: center;
    font-size: 10px;
  }
}
