.toolbar-logo {
  width: 100px;
  height: 100px;
  object-fit: contain;
  cursor: pointer;
}

.toolbar-icons {
  width: 20px;
  height: 20px;
  object-fit: contain;
}
