.dashboard-image {
  width: 100%;
  height: 100%;
}
.account-layout {
  height: 100vh;
  background: #f1f4f8;
}
.dashboard-layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: #f1f4f8;
}

.dashboard {
  flex: 1;
  margin: auto;
  padding: 30px 150px 0 150px;
}

.breacrumb-text {
  color: #a2add0;
  font-size: 14px;
  padding: 150px 0px 0px 110px;
}
.app-footer {
  /* flex-shrink: 0;
  width: 100%;
  text-align: center;
  margin-top: auto;
  background: #f1f4f8;
  padding: 15px 0; */
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  /* color: white; */
  text-align: center;
  background: #f1f4f8;
  padding: 15px 0;
}
.app-footer-link {
  color: #4191ff;
  cursor: pointer;
}
.card-design {
  box-shadow: 0px 4px 4px 0px #4a72d240 !important;
  border-radius: 10px !important;
  /* max-height: 170px; */
  min-height: 170px;
  width: "100%!important";
}

@media only screen and (max-width: 600px) {
  .dashboard {
    padding: 30px 40px 100px 40px;
  }
  .dashboard-image {
    width: 80px;
    height: 80px;
  }
  .card-design {
    max-height: 250px;
    min-height: 250px;
    overflow: hidden;
  }
  .card-design .description {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .breacrumb-text {
    color: #a2add0;
    font-size: 14px;
    padding: 120px 0px 0px 40px;
  }
}
@media only screen and (max-width: 375px) {
  .card-design {
    max-height: 300px;
    min-height: 300px;
    overflow: hidden;
  }
  .card-design .description {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 10;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
